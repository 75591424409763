import React, { useEffect, useState, useRef } from 'react';
import { Card } from '@abyss/web/ui/Card';
import { CopilotBody } from './CopilotBody';
import { Button } from '@abyss/web/ui/Button';
import { Popover } from '@abyss/web/ui/Popover';

export const Copilot = ({
  filterAIBest,
  setIsOpen,
  setLoading,
  savedDoctors,
  savedDrugs,
  onAddDoctorsClick,
  onAddDrugsClick,
  setCopilotState,
  copilotState
}) => {
  const [params, setParams] = useState({ name: '' });
  const [path, setPath] = useState(['start']);

  const messagesEndRef = useRef(null);

  const flow = {
    start: {
      views: [
        { content: ['Are you a UHC member?'] },
        { widget: { type: 'button', options: ['Yes', 'No'] } }
      ],
      name: 'start',
      path: 'share_first_name'
    },
    share_first_name: {
      views: [
        {
          content: [
            'Thank you for exploring your health care options with UnitedHealthcare.',
            'Would you like to share your first name?'
          ]
        },
        {
          widget: {
            type: 'button',
            options: ['Yes', 'No'],
            yes: 'get_first_name_inputbox',
            no: 'welcome_member_no_name'
          }
        }
      ],
      name: 'share_first_name',
      path: 'get_first_name_inputbox'
    },
    get_first_name_inputbox: {
      views: [
        {
          widget: {
            type: 'textField',
            placeholder: 'Enter of your name here',
            key: 'name'
          }
        }
      ],
      name: 'get_first_name_inputbox',
      path: 'welcome_member'
    },
    welcome_member_no_name: {
      views: [
        {
          content: [
            `That's okay! First, let's go over your health care needs.`,
            `There are many important factors when choosing a plan, but I want to know what's most important to you. That way, I can recommend plans that will fit your needs.`
          ]
        },
        {
          widget: {
            type: 'button',
            layout: 'column',
            options: [
              'I take prescription drugs',
              'I want to keep my doctors',
              'I am concerned about cost'
            ],
            itakeprescriptiondrugs: 'drugs_buttons',
            iwanttokeepmydoctors: 'doctor_initial_buttons',
            iamconcernedaboutcost: 'special_considerations',
            followUp: {
              iwanttokeepmydoctors: `Great, we will take you to a screen where you can search and add your doctors.`,
              iamconcernedaboutcost: `Noted. I'll prioritize plans that keep cost in mind.`
            }
          }
        }
      ],
      name: 'welcome_member',
      path: 'special_considerations'
    },
    welcome_member: {
      views: [
        {
          content: [
            `Hello, <strong>${params.name}</strong>! First, let's talk about your health care needs.`,
            'What is <strong>most important</strong> when you are considering health insurance plans?',
            `There are many important factors when choosing a plan, but I want to know what's most important to you. That way, I can recommend plans that will fit your needs.`
          ]
        },
        {
          widget: {
            type: 'button',
            layout: 'column',
            options: [
              'I take prescription drugs',
              'I want to keep my doctors',
              'I am concerned about cost'
            ],
            followUp: {
              iwanttokeepmydoctors: `Great, we will take you to a screen where you can search and add your doctors.`,
              iamconcernedaboutcost: `Noted. I'll prioritize plans that keep cost in mind.`
            },
            itakeprescriptiondrugs: 'drugs_buttons',
            iwanttokeepmydoctors: 'doctor_initial_buttons',
            iamconcernedaboutcost: 'special_considerations'
          }
        }
      ],
      name: 'welcome_member',
      path: 'special_considerations'
    },
    drugs_buttons: {
      views: [
        {
          content: [
            `Great, we will take you to a screen where you can search and add your drugs.`
          ]
        },
        {
          widget: {
            type: 'button',
            options: ['Go', `Nevermind I’ll add them later`],
            go: 'doctor_buttons',
            nevermindilladdthemlater: 'doctor_buttons',
            followUp: {
              go: `Thank you for adding your prescription drugs. This will help make my recommendation more accurate. <div style="margin-top: 20px;">Now that we have your drugs added, would you like to add your doctors?</div>`,
              nevermindilladdthemlater: `Would you like to add your doctors at this time?`
            },
            showDrugs: true
          }
        }
      ],
      name: 'drugs_buttons',
      path: 'doctor_buttons'
    },
    drugs_secondary_buttons: {
      views: [
        {
          widget: {
            type: 'button',
            options: ['Go', `Nevermind I’ll add them later`],
            go: 'special_considerations',
            nevermindilladdthemlater: 'special_considerations',
            followUp: {
              go: `Thank you for adding your prescription drugs. This will help make my recommendation more accurate.`
            },
            showDrugs: true
          }
        }
      ],
      name: 'drugs_secondary_buttons',
      path: 'special_considerations'
    },
    doctor_initial_buttons: {
      views: [
        {
          widget: {
            type: 'button',
            options: ['Go', `Nevermind I’ll add them later`],
            go: 'drugs_secondary_buttons',
            nevermindilladdthemlater: 'drugs_secondary_buttons',
            followUp: {
              go: `Thank you for adding your healthcare providers. This will help make my recommendation more accurate. <div style="margin-top: 20px;">Now that we have your doctors added, would you like to add your drugs?</div>`,
              nevermindilladdthemlater: `Would you like to add your drugs at this time?`
            },
            showDocs: true
          }
        }
      ],
      name: 'doctor_initial_buttons',
      path: 'drugs_secondary_buttons'
    },
    doctor_buttons: {
      views: [
        {
          widget: {
            type: 'button',
            options: ['Go', 'Nevermind I’ll add them later'],
            go: 'special_considerations',
            nevermindilladdthemlater: 'special_considerations',
            followUp: {
              go: `Thank you for adding your healthcare providers. This will help make my recommendation more accurate.`
            },
            showDocs: true
          }
        }
      ],
      name: 'doctor_buttons',
      path: 'special_considerations'
    },
    special_considerations: {
      views: [
        {
          content: [`Do you qualify for Extra Help? `]
        },
        {
          widget: {
            type: 'button',
            options: ['Yes', 'No', 'How do I know?'],
            yes: 'special_considerations_options',
            no: 'special_considerations_options',
            howdoiknow: 'special_considerations_options',
            followUp: {
              yes: `I’m grateful for the heads-up.`,
              no: `I appreciate the information.`,
              howdoiknow: `If approved for <strong>Extra Help</strong>, you'll receive a letter from the SSA or Medicare, and may notice lower prescription costs. You can also check your status online or by phone.`
            }
          }
        }
      ],
      name: 'special_considerations',
      path: 'special_considerations_options'
    },
    special_considerations_options: {
      views: [
        {
          content: [
            `Thank you for your time. Your answers allow me to be more helpful to you. Can you tell me if any of these situations apply to you? Choose all that apply.`
          ]
        },
        {
          widget: {
            type: 'checkbox',
            options: {
              one: `I have been diagnosed with diabetes,chronic heart failure, or a cardiovascular condition.`,
              two: `I live in a nursing home, long-term care facility, or assisted living facility. Or I live at home and require nursing home level care.`,
              three: `I have creditable Part D coverage.`,
              four: `None of these situations apply to me.`
            },
            optionActions: {
              one: 'results',
              two: 'results',
              three: 'results',
              four: 'special_needs'
            }
          }
        }
      ],
      name: 'special_considerations_options',
      path: 'out_of_pocket'
    },
    special_needs: {
      views: [
        {
          widget: {
            type: 'infobox',
            text: `Based on your answers, we’d like to offer you more assistance. Please call UnitedHealthcare at 1-888-834-3721 / TTY 711 8 a.m. to 8 p.m. 7 days a week.`,
            linkText: `View Special Needs plans`,
            linkUrl:
              'https://www.uhc.com/medicare/health-plans/plan-summary/55430/053/2024#MA'
          }
        }
      ],
      name: 'special_needs',
      path: 'out_of_pocket'
    },
    early_results: {
      views: [
        {
          content: [
            `Good news, there are 14 plans in your area. I can show you the plans now, or I can ask a few more questions to narrow your results.`
          ]
        },
        {
          widget: {
            type: 'button',
            layout: 'column',
            options: [
              `Yes, lets look at the plans`,
              `No, let's continue for now`
            ],
            yesletslookattheplans: 'exit',
            noletscontinuefornow: 'expenses'
          }
        }
      ],
      name: 'early_results'
    },
    expenses: {
      views: [
        {
          content: [
            `Considering plan expenses, which scenario do you think works best for you?`
          ]
        },
        {
          widget: {
            type: 'radio',
            layout: 'column',
            options: [
              `Minimal day-to-day costs, but paying more for significant issues`,
              `Regular care with extra protection for serious events`,
              `A compromise between the two`
            ]
          }
        }
      ],
      name: 'expenses'
    },
    chronic_conditions: {
      views: [
        {
          content: [
            `Noted. I'll prioritize plans that keep cost in mind.`,
            `Do you have any chronic conditions?`
          ]
        },
        {
          widget: {
            type: 'popover',
            inline: 'Why are we asking you this?',
            poptext: `If you have been diagnosed with diabetes (not including pre-diabetes), chronic heart failure (congestive heart failure), and/or a cardiovascular disorder (atrial fibrillation/ Afib, irregular heartbeat, other heart related complications), your health needs may be best met by a Chronic Special Needs plans (C-SNP).`
          }
        },
        {
          widget: {
            type: 'button',
            options: [`Yes`, `No`]
          }
        }
      ],
      name: 'chronic_conditions',
      path: 'out_of_pocket'
    },
    out_of_pocket: {
      views: [
        {
          content: [
            `I’m grateful for the heads-up.`,
            `We have some good news. In your zip code, 77001, all monthly premiums range between $0 and $48.`
          ]
        },
        {
          widget: {
            type: 'popover',
            inline: 'What are monthly premiums?',
            poptext: `A monthly premium is the fee you pay in exchange for coverage.`
          }
        },
        {
          content: [
            `What is your budget for out-of-pocket maximums? The range in your zip code is between $0 and $7,900.`
          ]
        },
        {
          widget: {
            type: 'popover',
            inline: 'What are out-of-pocket maximums?',
            poptext: `This is the amount of money you pay for covered services per calendar year.`
          }
        },
        {
          widget: {
            type: 'textField',
            placeholder: 'Type your out-of-pocket maximum budget here.',
            key: 'budget'
          }
        }
      ],
      name: 'out_of_pocket',
      path: 'results'
    },
    results: {
      views: [
        {
          content: [
            `Good news! There are 3 plans in your area with out-of-pocket maximums ranging from $6,700 and below. Take a moment to view the current plans and determine what is best for you.`
          ]
        },
        {
          widget: {
            type: 'button',
            layout: 'column',
            options: [`Show me the plans!`],
            results: true
          }
        }
      ],
      name: 'results'
    }
  };
  const [messages, setMessage] = useState([flow.start]);

  const updataParams = (args) => {
    if (args) {
      setParams((param) => {
        return { ...param, ...args };
      });
    }
    localStorage.setItem(
      'chatbotparams',
      JSON.stringify({ ...params, ...args })
    );
  };

  const updateMessage = (nextpath, widgetType, ...args) => {
    setPath((prev) => {
      return [...prev, nextpath];
    });

    let updateWidget = [...messages];

    switch (widgetType) {
      case 'button':
        updateWidget[[updateWidget.length - 1]].views.forEach((item) => {
          if (item?.widget && item?.widget.type === 'button') {
            item.widget = { ...item.widget, ...args[0] };
          }
        });

        setMessage(updateWidget);

        break;
      case 'checkbox':
        updateWidget[[updateWidget.length - 1]].views.forEach((item) => {
          if (item?.widget && item?.widget.type === 'checkbox') {
            item.widget = { ...item.widget, ...args[0] };
          }
        });

        setMessage(updateWidget);

        break;
      case 'radio':
        updateWidget[[updateWidget.length - 1]].views.forEach((item) => {
          if (item?.widget && item?.widget.type === 'radio') {
            item.widget = { ...item.widget, ...args[0] };
          }
        });

        setMessage(updateWidget);

        break;
      case 'textField':
        updateWidget[[updateWidget.length - 1]].views.forEach((item) => {
          if (item?.widget && item?.widget.type === 'textField') {
            item.widget = { ...item.widget, ...args[0] };
          }
        });

        setMessage(updateWidget);
        break;
      default:
        break;
    }

    setMessage((preState) => {
      localStorage.setItem(
        'chatbotmessage',
        JSON.stringify([...preState, flow?.[nextpath]])
      );

      return [...preState, flow?.[nextpath]];
    });
  };

  const resetAI = () => {
    // Remove local Storage
    localStorage.removeItem('chatbotmessage');
    localStorage.removeItem('chatbotparams');
    localStorage.removeItem('samAI');

    // Reload Page
    window.location.href = window.location.href;
  };

  useEffect(() => {
    const chatbotMessage = JSON.parse(
      localStorage.getItem('chatbotmessage') || '""'
    );
    const chatbotParams = JSON.parse(
      localStorage.getItem('chatbotparams') || '""'
    );
    if (chatbotMessage) {
      setMessage([...chatbotMessage]);
    }
    if (chatbotParams) {
      setParams({ ...chatbotParams });
    }
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, copilotState]);

  return (
    <React.Fragment>
      <Card
        css={{
          width: '100%',
          height: '100%',
          border: 'none',
          boxShadow: 'none'
        }}
      >
        {messages.map((message, index) => {
          return (
            <>
              <CopilotBody
                filterAIBest={filterAIBest}
                setIsOpen={setIsOpen}
                flowindex={index}
                views={message.views}
                updateMessage={updateMessage}
                updataParams={updataParams}
                nextpath={message.path}
                params
                path={path}
                name={message.name}
                advance={message.advance}
                total={Object.keys(messages).length}
                setLoading={setLoading}
                bodyKey={index}
                key={`${index}-copilotbody`}
                onAddDoctorsClick={onAddDoctorsClick}
                onAddDrugsClick={onAddDrugsClick}
                setCopilotState={setCopilotState}
              />
              {(message.name === 'results' ||
                message.name === 'special_needs') && (
                <div
                  style={{
                    width: '100%',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    marginTop: '20px',
                    marginBottom: '20px'
                  }}
                >
                  <Button
                    variant="tertiary"
                    style={{ width: '100%' }}
                    onClick={() => {
                      resetAI();
                    }}
                  >
                    Reset
                  </Button>
                </div>
              )}
            </>
          );
        })}
        <div ref={messagesEndRef} />
      </Card>
    </React.Fragment>
  );
};
