import Footer from 'components/footer/footer';
import Header from 'components/header/header';
import toastController from 'components/toast/toast-controller';
import useLocalStorage from 'hooks/useLocalStorage';
import MSPlanList from 'pages/ms-plan-list/ms-plan-list';
import PlanList from 'pages/plan-list/plan-list';
import Portfolio from 'pages/portfolio/portfolio';
import ShopperProfileDoctorsPage from 'pages/shopper-profile/pages/doctors-page';
import ShopperProfileDrugsPage from 'pages/shopper-profile/pages/drugs-page';
import ShopperProfileSavedPlansPage from 'pages/shopper-profile/pages/saved-plans-page';
import AddDoctorsSlideOver from 'pages/shopper-profile/partials/add-doctors-slide-over';
import AddDrugsSlideOver from 'pages/shopper-profile/partials/add-drugs-slide-over';
import AddSavedItemsModal from 'pages/shopper-profile/partials/add-saved-items-modal';
import RemoveDoctorModal from 'pages/shopper-profile/partials/remove-doctor-modal';
import RemoveDrugModal from 'pages/shopper-profile/partials/remove-drug-modal';
import ShopperProfileLayout from 'pages/shopper-profile/shopper-profile';
import ZipCodeSearch from 'pages/zip-code-search/zip-code-search';
import { Fragment, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';
import mockDrugsData from './data/mock-drugs.json';
import mockPlansData from './data/mock-plans.json';
import mockProvidersData from './data/mock-providers.json';
import ShopperProfilePlanDetailPage from './routes/shopper-profile-plan-detail';
import MSHome from 'pages/ms-home/ms-home';
import MSPlanCompare from 'pages/ms-plan-compare/ms-plan-compare';
import PlanCompare from 'pages/plan-compare/plan-compare';
import msPlans from 'data/ms-plans';
import { plansWithoutDetails } from 'data/plans';
import {
  planSectionsWithoutDetails,
  planSectionsWithDetails
} from 'data/plan-sections';

const ShopperProfilePrototype = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isSearchMode, setIsSearchMode] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [isAuthenticated, setIsAuthenticated] = useLocalStorage(
    'uhcSPAuthenticated',
    false
  );
  const [savedPlans, setSavedPlans] = useLocalStorage('uhcSPSavedPlans', []);
  const [savedDoctors, setSavedDoctors] = useLocalStorage(
    'uhcSPSavedDoctors',
    []
  );
  const [savedDrugs, setSavedDrugs] = useLocalStorage('uhcSPSavedDrugs', []);

  const [toRemoveDoctor, setToRemoveDoctor] = useState(null);
  const [toRemoveDrug, setToRemoveDrug] = useState(null);
  const [toAddPlan, setToAddPlan] = useState(null);

  // Slide Overs & Modals State
  const [isAddDoctorsSlideOverShown, setIsAddDoctorsSlideOverShown] =
    useState(false);
  const [isAddDrugsSlideOverShown, setIsAddDrugsSlideOverShown] =
    useState(false);
  const [isRemoveDoctorModalShown, setIsRemoveDoctorModalShown] =
    useState(false);
  const [isRemoveDrugModalShown, setIsRemoveDrugModalShown] = useState(false);
  const [isSavePlanModalShown, setIsSavePlanModalShown] = useState(false);
  const [copilotState, setCopilotState] = useState('active');

  // Autocomplete onChange event for searching doctors & drugs
  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleAddDoctorsSlideOverClose = () => {
    setIsAddDoctorsSlideOverShown(false);
    setIsSearchMode(false);
    setSearchValue('');
    setCopilotState('active');

    // if (savedDoctors.length > 0) {
    //   toastController.notify(
    //     'Your doctors have been added to your account',
    //     'See if your doctors are in network as you browse plans'
    //   );
    // }
  };

  const handleAddDrugsSlideOverClose = () => {
    setIsAddDrugsSlideOverShown(false);
    setIsSearchMode(false);
    setSearchValue('');
    setCopilotState('active');

    // if (savedDrugs.length > 0) {
    //   toastController.notify(
    //     'Your drugs have been added to your account',
    //     'See estimated drug pricing as you browse plans'
    //   );
    // }
  };

  const handleDoctorItemSelect = (item) => {
    const doctor = mockProvidersData.find((x) => x.id === item.value);
    setSavedDoctors([...savedDoctors, doctor]);
    setSearchValue('');
    setIsSearchMode(false);
  };

  const handleDrugItemSelect = (item) => {
    const drug = mockDrugsData.find((x) => x.id === item.value);
    setSavedDrugs([...savedDrugs, drug]);
    setSearchValue('');
    setIsSearchMode(false);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setIsSearchMode(true);
  };

  const removeDoctorById = (id) => {
    const doctor = savedDoctors.find((x) => x.id === id);
    setToRemoveDoctor(doctor);
    setIsRemoveDoctorModalShown(true);
  };

  const removeDrugById = (id) => {
    const drug = savedDrugs.find((x) => x.id === id);
    setToRemoveDrug(drug);
    setIsRemoveDrugModalShown(true);
  };

  const confirmRemoveDoctor = () => {
    const newSavedDoctors = savedDoctors.filter(
      (x) => x.id !== toRemoveDoctor.id
    );
    setSavedDoctors(newSavedDoctors);

    setIsRemoveDoctorModalShown(false);
    setToRemoveDoctor(null);
  };

  const confirmRemoveDrug = () => {
    const newSavedDrugs = savedDrugs.filter((x) => x.id !== toRemoveDrug.id);
    setSavedDrugs(newSavedDrugs);

    setIsRemoveDrugModalShown(false);
    setToRemoveDrug(null);
  };

  const handleAddSavedItemsModalClose = () => {
    setIsSavePlanModalShown(false);
    setToAddPlan(null);
  };

  const handlePlanSaveToggle = (event, value, plan) => {
    if (value) {
      setToAddPlan(plan);
      setIsSavePlanModalShown(true);
      setSavedPlans([...savedPlans, plan]);
    } else {
      const newSavedPlans = savedPlans.filter((x) => x.id !== plan.id);
      setSavedPlans(newSavedPlans);
    }
  };

  const handleZipSubmit = (event) => {
    event.preventDefault();
    navigate('/plan-summary');
  };

  const AuthLogin = () => {
    setIsAuthenticated(true);
    return <Navigate to="/account/plans/saved" />;
  };

  const AuthLogout = () => {
    setIsAuthenticated(false);
    setSavedDrugs([]);
    setSavedDoctors([]);
    setSavedPlans([]);
    return <Navigate to="/" />;
  };

  useEffect(() => {
    setIsAddDoctorsSlideOverShown(false);
    setIsAddDrugsSlideOverShown(false);
    setIsRemoveDoctorModalShown(false);
    setIsRemoveDrugModalShown(false);
    setIsSavePlanModalShown(false);
  }, [location]);

  return (
    <Fragment>
      <Header isAuthenticated={isAuthenticated} savedPlans={savedPlans} />
      <main>
        <Routes>
          <Route path="/" element={<MSHome />} />
          <Route path="/auth/login" element={<AuthLogin />} />
          <Route path="/auth/logout" element={<AuthLogout />} />
          <Route
            path="/health-plans"
            element={<ZipCodeSearch onSubmit={handleZipSubmit} />}
          />
          <Route path="/sam1a" element={<MSHome samType={'one'} />} />
          <Route path="/sam2a" element={<MSHome samType={'two'} />} />
          <Route path="/sam3a" element={<MSHome samType={'three'} />} />
          <Route
            path="sam1b"
            element={
              <PlanList
                availablePlans={mockPlansData.filter((x) => x.type === 'MS')}
                isAuthenticated={isAuthenticated}
                savedDoctors={savedDoctors}
                savedDrugs={savedDrugs}
                savedPlans={savedPlans}
                onAddDoctorsClick={() => setIsAddDoctorsSlideOverShown(true)}
                onAddDrugsClick={() => setIsAddDrugsSlideOverShown(true)}
                onRemoveDoctorClick={removeDoctorById}
                onRemoveDrugClick={removeDrugById}
                onPlanSaveToggle={handlePlanSaveToggle}
                isSecondary={true}
                hasFilter={false}
                samType={'one'}
              />
            }
          />
          <Route
            path="sam2b"
            element={
              <PlanList
                availablePlans={mockPlansData.filter((x) => x.type === 'MS')}
                isAuthenticated={isAuthenticated}
                savedDoctors={savedDoctors}
                savedDrugs={savedDrugs}
                savedPlans={savedPlans}
                onAddDoctorsClick={() => setIsAddDoctorsSlideOverShown(true)}
                onAddDrugsClick={() => setIsAddDrugsSlideOverShown(true)}
                onRemoveDoctorClick={removeDoctorById}
                onRemoveDrugClick={removeDrugById}
                onPlanSaveToggle={handlePlanSaveToggle}
                isSecondary={true}
                hasFilter={false}
                samType={'two'}
              />
            }
          />
          <Route
            path="sam3b"
            element={
              <PlanList
                availablePlans={mockPlansData.filter((x) => x.type === 'MS')}
                isAuthenticated={isAuthenticated}
                savedDoctors={savedDoctors}
                savedDrugs={savedDrugs}
                savedPlans={savedPlans}
                onAddDoctorsClick={() => setIsAddDoctorsSlideOverShown(true)}
                onAddDrugsClick={() => setIsAddDrugsSlideOverShown(true)}
                onRemoveDoctorClick={removeDoctorById}
                onRemoveDrugClick={removeDrugById}
                onPlanSaveToggle={handlePlanSaveToggle}
                isSecondary={true}
                hasFilter={false}
                samType={'three'}
              />
            }
          />
          <Route
            path="ma-landing"
            element={
              <picture
                onClick={() => {
                  navigate('/plan-compare/ma');
                }}
              >
                <source
                  srcset="./assets/images/ma-landing.png"
                  media="(min-width: 600px)"
                />
                <img
                  srcset="./assets/images/ma-landing-mobile.png"
                  style={{ maxWidth: '100%', width: '100%' }}
                />
              </picture>
            }
          />
          <Route
            path="ms-landing"
            element={
              <picture
                onClick={() => {
                  navigate('/plan-compare/');
                }}
              >
                <source
                  srcset="./assets/images/ms-landing.png"
                  media="(min-width: 600px)"
                />
                <img
                  srcset="./assets/images/ms-landing-mobile.png"
                  style={{ maxWidth: '100%', width: '100%' }}
                />
              </picture>
            }
          />
          <Route
            path="plan-compare"
            element={
              <MSPlanCompare
                data={msPlans}
                showInformationAdded={false}
                showTabs={false}
              />
            }
          />
          <Route
            path="plan-compare/ma"
            element={
              <PlanCompare
                data={plansWithoutDetails}
                sections={planSectionsWithoutDetails}
              />
            }
          />
          {/* Plan Summary Routes */}
          <Route path="/">
            <Route index element={<Portfolio />} />
            <Route
              path="medicare-advantage-plans"
              element={
                <PlanList
                  availablePlans={mockPlansData.filter((x) => x.type === 'MA')}
                  isAuthenticated={isAuthenticated}
                  savedDoctors={savedDoctors}
                  savedDrugs={savedDrugs}
                  savedPlans={savedPlans}
                  onAddDoctorsClick={() => setIsAddDoctorsSlideOverShown(true)}
                  onAddDrugsClick={() => setIsAddDrugsSlideOverShown(true)}
                  onRemoveDoctorClick={removeDoctorById}
                  onRemoveDrugClick={removeDrugById}
                  onPlanSaveToggle={handlePlanSaveToggle}
                  isSecondary={false}
                  hasFilter={true}
                  key={1}
                  copilotState={copilotState}
                  setCopilotState={setCopilotState}
                />
              }
            />
            <Route
              path="medicare-advantage-plans2"
              element={
                <PlanList
                  availablePlans={mockPlansData.filter((x) => x.type === 'MA')}
                  isAuthenticated={isAuthenticated}
                  savedDoctors={savedDoctors}
                  savedDrugs={savedDrugs}
                  savedPlans={savedPlans}
                  onAddDoctorsClick={() => setIsAddDoctorsSlideOverShown(true)}
                  onAddDrugsClick={() => setIsAddDrugsSlideOverShown(true)}
                  onRemoveDoctorClick={removeDoctorById}
                  onRemoveDrugClick={removeDrugById}
                  onPlanSaveToggle={handlePlanSaveToggle}
                  isSecondary={true}
                  hasFilter={true}
                  key={2}
                />
              }
            />
            <Route
              path="medicare-supplement-plans"
              element={
                <PlanList
                  availablePlans={mockPlansData.filter((x) => x.type === 'MS')}
                  isAuthenticated={isAuthenticated}
                  savedDoctors={savedDoctors}
                  savedDrugs={savedDrugs}
                  savedPlans={savedPlans}
                  onAddDoctorsClick={() => setIsAddDoctorsSlideOverShown(true)}
                  onAddDrugsClick={() => setIsAddDrugsSlideOverShown(true)}
                  onRemoveDoctorClick={removeDoctorById}
                  onRemoveDrugClick={removeDrugById}
                  onPlanSaveToggle={handlePlanSaveToggle}
                  isSecondary={true}
                  hasFilter={false}
                />
              }
            />
            <Route path="*" element={<Portfolio />} />
          </Route>
          {/* Plan Details Route */}
          <Route path="/details">
            <Route
              path=":planId"
              element={
                <ShopperProfilePlanDetailPage
                  isAuthenticated={isAuthenticated}
                  savedDoctors={savedDoctors}
                  savedDrugs={savedDrugs}
                  savedPlans={savedPlans}
                  onAddDoctorsClick={() => setIsAddDoctorsSlideOverShown(true)}
                  onAddDrugsClick={() => setIsAddDrugsSlideOverShown(true)}
                  onPlanSaveToggle={handlePlanSaveToggle}
                />
              }
            />
            <Route path="" element={<Navigate to="/plan-summary" />} />
          </Route>
          {/* Account Routes */}
          <Route
            path="/account"
            element={<ShopperProfileLayout isAuthenticated={isAuthenticated} />}
          >
            <Route index element={<Navigate to="plans/saved" />} />
            <Route
              path="plans"
              element={<Navigate to="/account/plans/saved" />}
            />
            <Route
              path="plans/saved"
              element={
                <ShopperProfileSavedPlansPage
                  savedDoctors={savedDoctors}
                  savedDrugs={savedDrugs}
                  savedPlans={savedPlans}
                  onAddDoctorsClick={() => setIsAddDoctorsSlideOverShown(true)}
                  onAddDrugsClick={() => setIsAddDrugsSlideOverShown(true)}
                  onPlanSaveToggle={handlePlanSaveToggle}
                />
              }
            />
            <Route
              path="drugs"
              element={
                <ShopperProfileDrugsPage
                  data={savedDrugs}
                  onAddDrugsClick={() => setIsAddDrugsSlideOverShown(true)}
                  onRemoveDrugClick={removeDrugById}
                />
              }
            />
            <Route
              path="doctors"
              element={
                <ShopperProfileDoctorsPage
                  data={savedDoctors}
                  onAddDoctorsClick={() => setIsAddDoctorsSlideOverShown(true)}
                  onRemoveDoctorClick={removeDoctorById}
                />
              }
            />
          </Route>
          {/* <Route path="*" element={<Navigate to="/" />} /> */}
        </Routes>
      </main>
      <Footer />

      {/* Slide Overs */}
      <AddDrugsSlideOver
        isSearchMode={isSearchMode}
        isShown={isAddDrugsSlideOverShown}
        savedData={savedDrugs}
        searchData={mockDrugsData}
        searchValue={searchValue}
        onCancel={() => setIsSearchMode(false)}
        onChange={handleChange}
        onItemSelect={handleDrugItemSelect}
        onClose={handleAddDrugsSlideOverClose}
        onRemove={removeDrugById}
        onSearchSubmit={handleSearchSubmit}
      />

      <AddDoctorsSlideOver
        isSearchMode={isSearchMode}
        isShown={isAddDoctorsSlideOverShown}
        savedData={savedDoctors}
        searchData={mockProvidersData}
        searchValue={searchValue}
        onCancel={() => setIsSearchMode(false)}
        onChange={handleChange}
        onItemSelect={handleDoctorItemSelect}
        onClose={handleAddDoctorsSlideOverClose}
        onRemove={removeDoctorById}
        onSearchSubmit={handleSearchSubmit}
      />

      {/* Modals */}
      <RemoveDoctorModal
        data={toRemoveDoctor}
        isShown={isRemoveDoctorModalShown}
        onClose={() => setIsRemoveDoctorModalShown(false)}
        onConfirm={() => confirmRemoveDoctor()}
      />
      <RemoveDrugModal
        data={toRemoveDrug}
        isShown={isRemoveDrugModalShown}
        onClose={() => setIsRemoveDrugModalShown(false)}
        onConfirm={() => confirmRemoveDrug()}
      />
      <AddSavedItemsModal
        data={toAddPlan}
        isShown={isSavePlanModalShown}
        onClose={handleAddSavedItemsModalClose}
      />

      <Toaster position="top-right" />
    </Fragment>
  );
};

export default ShopperProfilePrototype;
