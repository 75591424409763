import './sam.scss';

import { useState, useRef, useEffect } from 'react';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import useOutsideClick from 'partials/outside-detect/outside-detect';
import classNames from 'classnames';

const SamAI = ({ setIsOpen }) => {
  const [buttonChatState, setButtonChatState] = useState(
    localStorage.getItem('sam2')
      ? JSON.parse(localStorage.getItem('sam2'))
      : true
  );
  const [buttonPhoneState, setButtonPhoneState] = useState(
    localStorage.getItem('sam2')
      ? JSON.parse(localStorage.getItem('sam2'))
      : true
  );
  const [isPhoneModalShown, setIsPhoneModalShown] = useState(false);
  const [isChatModalShown, setIsChatModalShown] = useState(false);
  const ref = useRef();

  useEffect(() => {
    setTimeout(() => {
      setButtonChatState(false);
      setButtonPhoneState(false);
      localStorage.setItem('samAI', false);
    }, 4000);
  }, []);

  useOutsideClick(ref, () => {
    if (isChatModalShown === false) {
      return;
    }

    setIsChatModalShown(false);
  });

  return (
    <div className="sam samai">
      <div
        className={classNames(
          'samTwo__contain samTwo samTwo--ai storageClosed',
          {
            isOpen: buttonChatState,
            isClosed: !buttonChatState
          }
        )}
      >
        <button
          className="samTwo__modal"
          data-type="chat"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <span className="samTwo__text">Continue plan selection</span>
          <span>
            <IconMaterial
              icon="smart_toy"
              color="#fff"
              variant="outlined"
              style={{ marginTop: '3px', marginRight: '-2px' }}
            />
          </span>
        </button>
      </div>
    </div>
  );
};

export default SamAI;
