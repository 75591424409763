/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { Button } from '@abyss/web/ui/Button';

export const CopilotRadio = ({ viewItem, updateMessage, flowindex }) => {
  const [value, setValue] = useState(viewItem?.widget?.choice || []);
  const [disable, setDisable] = useState(false);

  const onSubmit = () => {
    let nextpath = 'results';
    setDisable(true);

    const data = { choice: value, isDisabled: true };

    updateMessage(nextpath, viewItem.widget?.type, data, flowindex);
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <RadioGroup
          value={value}
          onChange={(e) => {
            console.log(e.target.value);
            setValue(e.target.value);
          }}
          size="sm"
          descriptorsDisplay="column"
          isDisabled={disable || viewItem.widget.isDisabled ? 'true' : false}
        >
          {Object.entries(viewItem?.widget?.options).map((option, index) => {
            return (
              <RadioGroup.Radio
                css={{
                  'abyss-radio-label': {
                    fontSize: '18px',
                    marginBottom: '6px'
                  }
                }}
                label={option[1]}
                value={option[0]}
              />
            );
          })}
        </RadioGroup>
        <Button
          variant="outline"
          onClick={() => {
            onSubmit();
          }}
          style={{
            marginRight: '4px',
            minWidth: '110px',
            maxWidth: `110px`,
            marginTop: '20px'
          }}
          css={{
            backgroundColor: viewItem.widget.choice ? '#D9E9FA' : ' #FFFFFF',
            cursor:
              disable || viewItem.widget.isDisabled ? 'not-allowed' : undefined,
            pointerEvents:
              disable || viewItem.widget.isDisabled ? 'none' : undefined
          }}
        >
          Submit
        </Button>
      </div>
    </>
  );
};
