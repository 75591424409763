/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { CheckboxGroup } from '@abyss/web/ui/CheckboxGroup';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { Button } from '@abyss/web/ui/Button';

export const CopilotCheckboxes = ({ viewItem, updateMessage, flowindex }) => {
  const [value, setValue] = useState(viewItem?.widget?.choice || []);
  const [disable, setDisable] = useState(false);

  const onSubmit = () => {
    let nextpath = 'early_results';
    setDisable(true);
    console.log(value);

    const data = { choice: value, isDisabled: true };

    if (value.includes('one') && value.includes('three')) {
      nextpath = 'results';
    }

    // speical needs
    if (value.includes('two')) {
      nextpath = 'special_needs';
    }

    updateMessage(nextpath, viewItem.widget?.type, data, flowindex);
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CheckboxGroup
          value={value}
          onChange={(e) => {
            if (e.slice(-1)[0] === 'four') {
              e = ['four'];
            } else if (e.slice(-1)[0] !== 'four') {
              e = e.filter((item) => item !== 'four');
            }

            setValue(e);
          }}
          size="sm"
          descriptorsDisplay="column"
          isDisabled={disable || viewItem.widget.isDisabled ? 'true' : false}
        >
          {Object.entries(viewItem?.widget?.options).map((option, index) => {
            return (
              <Checkbox
                css={{
                  'abyss-checkbox-label': {
                    fontSize: '18px',
                    marginBottom: '6px'
                  }
                }}
                label={option[1]}
                value={option[0]}
              />
            );
          })}
        </CheckboxGroup>
        <Button
          variant="outline"
          onClick={() => {
            onSubmit();
          }}
          style={{
            marginRight: '4px',
            minWidth: '110px',
            maxWidth: `110px`,
            marginTop: '20px'
          }}
          css={{
            backgroundColor: viewItem.widget.choice ? '#D9E9FA' : ' #FFFFFF',
            cursor:
              disable || viewItem.widget.isDisabled ? 'not-allowed' : undefined,
            pointerEvents:
              disable || viewItem.widget.isDisabled ? 'none' : undefined
          }}
        >
          Submit
        </Button>
      </div>
    </>
  );
};
