/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { Popover } from '@abyss/web/ui/Popover';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';

export const CopilotPopover = ({ viewItem }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsPopoverOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Popover
      content={viewItem.widget.poptext}
      showClose={false}
      width={326}
      css={{
        'abyss-popover-content-container': {
          fontSize: '18px',
          overflow: 'hidden'
        }
      }}
      open={isPopoverOpen}
      onClick={() => {
        setIsPopoverOpen(!isPopoverOpen);
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 8,
          marginBottom: '20px'
        }}
        ref={dropdownRef}
      >
        <IconSymbol icon="info" />
        <span
          style={{
            color: '#196ECF',
            textDecoration: 'underline',
            fontSize: '16px'
          }}
        >
          {viewItem.widget.inline}
        </span>
      </div>
    </Popover>
  );
};
