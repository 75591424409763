/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useId } from 'react';
import { Box } from '@abyss/web/ui/Box';
import { Text } from '@abyss/web/ui/Text';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Button } from '@abyss/web/ui/Button';
import { TextInput } from '@abyss/web/ui/TextInput';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { styled } from '@abyss/web/tools/styled';
import { CopilotPopover } from './CopilotPopover';
import { CopilotCheckboxes } from './CopilotCheckboxes';
import { CopilotRadio } from './CopilotRadio';
import { Link } from '@abyss/web/ui/Link';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';

export const CopilotBody = ({
  setIsOpen,
  setLoading,
  filterAIBest,
  flowindex,
  total,
  nextpath,
  updateMessage,
  updataParams,
  content,
  widget,
  params,
  path,
  name,
  advance,
  views,
  bodyKey,
  onAddDoctorsClick,
  onAddDrugsClick,
  setCopilotState
}) => {
  const [activeButton, setActiveButton] = useState(null);
  const [activeText, setActiveText] = useState(null);
  const [value, setValue] = useState({ ...params });
  const [disable, setDisable] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  return (
    <div style={{ marginBottom: '20px' }} key={`${bodyKey}${name}`}>
      <Box
        style={{
          display: 'flex',
          flexFlow: 'column',
          background: 'transparent',
          padding: '0 16px'
        }}
      >
        {views.map((viewItem, viewIndex) => {
          return (
            <>
              {viewItem.content?.length > 0 &&
                viewItem.content.map((text, index) => {
                  return (
                    <Box
                      style={{
                        padding: 0,
                        background: 'transparent',
                        marginBottom: '20px'
                      }}
                      key={`${index}-${name}-content`}
                    >
                      <Text
                        dangerouslySetInnerHTML={{ __html: text }}
                        css={{ fontSize: '18px' }}
                      ></Text>
                    </Box>
                  );
                })}

              {viewItem.widget?.type === 'button' && (
                <Box
                  style={{
                    display: 'flex',
                    flexFlow: `${viewItem.widget.layout}`,
                    gap: '10px',
                    padding: 0,
                    background: 'transparent'
                  }}
                  key={`${bodyKey}-${name}-button`}
                >
                  {viewItem.widget.options.map((option, index) => {
                    return (
                      <Button
                        variant="outline"
                        key={`${index}-${name}-button-button`}
                        style={{ marginRight: '4px', minWidth: '110px' }}
                        onClick={(e) => {
                          if (viewItem.widget?.results) {
                            filterAIBest();
                            setIsOpen(false);
                            setLoading(true);
                            setTimeout(() => {
                              setLoading(false);
                            }, '1200');
                            return;
                          }

                          // show drugs drawer
                          if (viewItem.widget?.showDrugs && option === 'Go') {
                            e.preventDefault();
                            setCopilotState('disable');
                            onAddDrugsClick();
                          }

                          // show doctors drawer
                          if (viewItem.widget?.showDocs && option === 'Go') {
                            e.preventDefault();
                            setCopilotState('disable');
                            onAddDoctorsClick();
                          }

                          setActiveButton(index);
                          setDisable(true);
                          const data = { choice: option, isDisabled: true };
                          const selected = option
                            .toLowerCase()
                            .replace(/\s+/g, '')
                            .replace(/[^a-zA-Z ]/g, '');

                          nextpath = viewItem.widget[selected] || nextpath;

                          setSelectedOption(selected);

                          if (nextpath === 'exit') {
                            filterAIBest();
                            setIsOpen(false);
                            setLoading(true);
                            setDisable(false);
                            setTimeout(() => {
                              setLoading(false);
                            }, '1200');
                            return;
                          }

                          updateMessage(
                            nextpath,
                            viewItem.widget?.type,
                            data,
                            flowindex
                          );
                        }}
                        css={{
                          backgroundColor:
                            activeButton === index ||
                            (viewItem.widget.choice &&
                              option === viewItem.widget.choice)
                              ? '#D9E9FA'
                              : ' #FFFFFF',
                          cursor:
                            disable || viewItem.widget.isDisabled
                              ? 'not-allowed'
                              : undefined,
                          pointerEvents:
                            disable || viewItem.widget.isDisabled
                              ? 'none'
                              : undefined
                        }}
                      >
                        {option}
                      </Button>
                    );
                  })}
                </Box>
              )}

              {viewItem.widget?.type === 'checkbox' && (
                <Box
                  style={{
                    display: 'flex',
                    flexFlow: `${viewItem.widget.layout}`,
                    gap: '10px',
                    padding: 0,
                    background: 'transparent'
                  }}
                  key={`${bodyKey}-${name}-checkbox`}
                >
                  <CopilotCheckboxes
                    viewItem={viewItem}
                    updateMessage={updateMessage}
                    flowindex={flowindex}
                  />
                </Box>
              )}

              {viewItem.widget?.type === 'radio' && (
                <Box
                  style={{
                    display: 'flex',
                    flexFlow: `${viewItem.widget.layout}`,
                    gap: '10px',
                    padding: 0,
                    background: 'transparent'
                  }}
                  key={`${bodyKey}-${name}-radio`}
                >
                  <CopilotRadio
                    viewItem={viewItem}
                    updateMessage={updateMessage}
                    flowindex={flowindex}
                  />
                </Box>
              )}

              {viewItem.widget?.type === 'popover' && (
                <Box
                  style={{
                    padding: 0,
                    background: 'transparent'
                  }}
                  key={`${bodyKey}-${name}-popover`}
                >
                  <CopilotPopover viewItem={viewItem} />
                </Box>
              )}

              {viewItem.widget?.type === 'textField' && (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    const data = { choice: value, isDisabled: true };

                    setActiveText(flowindex);
                    updateMessage(
                      nextpath,
                      viewItem.widget?.type,
                      data,
                      flowindex
                    );
                  }}
                  style={{
                    display:
                      disable || viewItem.widget.isDisabled ? 'none' : 'block'
                  }}
                  key={`${bodyKey}-${name}-textfield`}
                >
                  <TextInput
                    label="TextInput Sandbox"
                    value={
                      typeof viewItem?.widget?.choice === 'object'
                        ? Object.values(viewItem?.widget?.choice)[0]
                        : value?.[viewItem.widget.key]
                    }
                    hideLabel
                    css={{
                      'abyss-text-input': {
                        fontSize: '18px !important'
                      }
                    }}
                    placeholder={viewItem.widget.placeholder}
                    inputRightElement={
                      <button type="submit">
                        <IconMaterial
                          icon="arrow_circle_right"
                          size="20px"
                          color="$primary1"
                        />
                      </button>
                    }
                    validators={{ required: true }}
                    onChange={(event) => {
                      const data = {
                        [viewItem.widget.key]: `${event.target.value}`
                      };
                      updataParams(data);
                      return setValue(data);
                    }}
                    isDisabled={disable || viewItem.widget.isDisabled}
                  />
                </form>
              )}

              {viewItem?.widget?.followUp &&
                Object.entries(viewItem?.widget?.followUp).map(
                  ([key, value]) => {
                    const savedSelected = viewItem?.widget?.choice
                      ?.toLowerCase()
                      .replace(/\s+/g, '')
                      .replace(/[^a-zA-Z ]/g, '');

                    if (key !== selectedOption && key !== savedSelected) {
                      return false;
                    }
                    return (
                      <Box
                        style={{
                          padding: 0,
                          background: 'transparent',
                          marginTop: '20px'
                        }}
                      >
                        <Text
                          dangerouslySetInnerHTML={{ __html: value }}
                          css={{ fontSize: '18px' }}
                        ></Text>
                      </Box>
                    );
                  }
                )}

              {viewItem.widget?.type === 'infobox' && (
                <Box color="$tint2" padding="$md">
                  <Text
                    dangerouslySetInnerHTML={{ __html: viewItem.widget.text }}
                    css={{ fontSize: '18px' }}
                  ></Text>
                  <div>
                    <Link
                      after={<IconSymbol icon="arrow_right_alt" />}
                      css={{ textDecoration: 'none', marginTop: '10px' }}
                      href={viewItem.widget.linkUrl}
                    >
                      {viewItem.widget.linkText}
                    </Link>
                  </div>
                </Box>
              )}
            </>
          );
        })}
      </Box>
    </div>
  );
};
