import React from 'react';
import ReactDOM from 'react-dom';
import Prototypes from 'prototypes';

import '@bswing/uhc-core/dist/fonts.css';
import '@bswing/uhc-core/dist/main.css';
import 'index.scss';

import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import { createTheme } from '@abyss/web/tools/theme';

const theme = createTheme('uhc');

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Prototypes />
  </ThemeProvider>,
  document.getElementById('root')
);
